import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic08 from '../assets/images/pic08.jpg'
import sticker10 from '../assets/images/sticker10.png'
import pic09 from '../assets/images/pic09.jpg'
import pic10 from '../assets/images/pic10.jpg'

const BannerLanding = props => (
  <section id="banner" className="style2">
    <div className="inner">
      <header className="major">
        <h1>The Gemerator</h1>
      </header>
      <div className="content">
        <p>
          A simple python program for quickly generating many
          <br />
          stickers for Augmented Reality content
        </p>
      </div>
    </div>
  </section>
)

const ARStickers = props => (
  <Layout>
    <Helmet>
      <title>The Gemerator</title>
      <meta name="description" content="Gemerator Info" />
    </Helmet>

    <BannerLanding />

    <div id="main">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h2 id="arstickers">AR Stickers?</h2>
          </header>
          <p>
            One of my bigger interests lately has been augmented reality.
            Specifically, giving control over AR content to businesses and
            venues via an app at runtime, which could set content for a
            secondary consumer app tied to their venue.
          </p>

          <p>
            Businesses would scan an image and choose content and assets to
            overlay when users viewed the same images with the consumer app!
            Neat idea.
          </p>

          <p>
            One component of that solution could be to generate a series of
            branded "stickers" that someone could set up in their space. We can
            programatically grade and combine sets of images into AR Image
            databases (at least for Google's ARCore anyways) that could then
            ship for a set of business and consumer apps! This is to say nothing
            of programatically updating those images after install.
          </p>

          <p>
            As far as image generation we just need to figure out a way to
            randomly generate patterns that look good and are easily
            recognizeable by AR apps.
          </p>

          <h3 id="thegemerator">The Gemerator</h3>

          <p>
            Enter the topic of this post..{' '}
            <a href="https://github.com/jrounsav/gemerator">The Gemerator</a>
          </p>

          <p>
            Built with Python 3. This fun little program uses ImageMagick to
            generate random sets of gemstones from a mac terminal. The gemstones
            are all dropped into a folder called "generated" which can then be
            utilized by another program, not included here, to grade and compile
            the DBs formerly mentioned.
          </p>

          <p>
            Using JSON configs we can easily update attributes for color,
            propensity &amp; viewport/gem size. Eventually I'd like to take it
            even further by enabling the random placement of say...company
            logos? Really anything.
          </p>

          <h3 id="anyways">Anyways</h3>

          <p>
            I had a lot of fun building out this piece of my AR solution! It was
            my first time dabbling with Python 3 and ImageMagick which ended up
            being a really great experience. I can't wait to build out more of
            this to take advantage of IMs other features.
          </p>
        </div>
      </section>
      <section id="two" className="spotlights">
        <section>
          <Link to="/generic" className="image">
            <img src={sticker10} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>ASU Coloring</h3>
              </header>
              <p>
                Example sticker using a JSON config I developed with my current
                employer's colors in mind. You can use pretty much whatever you
                want though, so long as there is some decent contrast for your
                AR applications to pick up on.
              </p>
            </div>
          </div>
        </section>
      </section>
    </div>
  </Layout>
)

export default ARStickers
